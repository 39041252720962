import { useState, useEffect } from 'react';
import { ConfigVariables } from '../config';
import { WatchlistToken } from '@BoolDigital/sizzle-types';

export const useWatchedTokenRatesWatched = (userTokenWatchlistId: number): { rates?: string[], isLoading: boolean, isError: boolean } => {
  const [data, setData] = useState<string[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchWatchedTokenDefiRates = async (id: number) => {
        if(!userTokenWatchlistId) {
            return;
        }
        setIsLoading(true);

        try {
            const queryParams = new URLSearchParams({
                userTokenWatchlistId: userTokenWatchlistId.toString(),
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/watchlist/token-defi-watched-rates?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
          
            if (!response.ok) {
                throw new Error(`Error fetching defi rates: ${response.statusText}`);
            }
            
            setData(await response.json() as string[]);
        } catch (e) {
            setIsError(true);
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    fetchWatchedTokenDefiRates(userTokenWatchlistId)
  }, [userTokenWatchlistId]);

  return { rates: data, isLoading, isError };
}