import React, { useState, useEffect } from 'react';

const DotLoader = () => {
    const [dots, setDots] = useState('.');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(dots => dots.length < 3 ? dots + '.' : '.');
        }, 500); // Change dot count every 500ms

        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ fontSize: '20px' }}>
            {dots}
        </div>
    );
}

export default DotLoader;