import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Chain, configureChains, createClient } from 'wagmi'
import { arbitrum, mainnet, polygon, hardhat } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ConfigVariables } from '../config';

const mainnetFork: Chain = {
  name: 'Bool Test Fork',
  network: 'bool-test',
  id: 1227,
  nativeCurrency: {
    name: 'Bool Eth',
    symbol: 'bETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://server.booldigital.com/test-fork']
    },
    public: {
      http: ['https://server.booldigital.com/test-fork']
    }
  }
}

const chains = [mainnet, mainnetFork, hardhat, arbitrum, polygon]
const projectId: string = ConfigVariables.WALLETCONNECT_PROJECT_ID ?? '';

const { provider } = configureChains(chains, [
  w3mProvider({ projectId }), 
  jsonRpcProvider({
    rpc: (chain) => ({
      http: chain.rpcUrls.default?.http?.[0] ||  'https://server.booldigital.com/test-fork'
    }),
    stallTimeout: 5000
  })
  ],
  { 
    targetQuorum: 1,
    minQuorum: 1
  }
)

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: 2, chains, projectId }),
  provider
})
export const ethereumClient = new EthereumClient(wagmiClient, chains);