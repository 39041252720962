import { useEffect, useState } from "react";
import { ConfigVariables } from "../config";

const useToken = (address: string | undefined) => {
    const [token, setToken] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState<any>();

    useEffect(() => {
        const fetchToken = async () => {
            if (!address) return

            setLoading(true);
            try {
                const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/token/${address}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setLoading(false);
                setErr(undefined)
                setToken(data);
            } catch (err) {
                setErr(err)
            }
        }

        fetchToken();
    }, [address])

    return { token, loading, err };
}

export default useToken;