import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Select, { CSSObjectWithLabel, OptionProps, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ConfigVariables } from '../config';

interface TokenSelectProps {
  onTokenSelected?: (token: any) => void; // Prop to pass the selected token to the parent
}

export interface TokenSelectHandle {
  selectWETH: () => Promise<void>;
}

const TokenSelect = forwardRef<TokenSelectHandle, TokenSelectProps>(({ onTokenSelected }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [tokens, setTokens] = useState<any[]>([]);
  const [selectedToken, setSelectedToken] = useState<{ value: string, label: string } | null>(null);

  const isUniswap = window.location.pathname === "/uniswap";
  const SelectComponent = isUniswap ? CreatableSelect : Select;

  useImperativeHandle(ref, () => ({
    selectWETH: async () => {
      const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/search?query=${encodeURIComponent('weth')}`);
      const data = await response.json();
      const wethToken = data.find((t: any) => t.symbol === 'weth' && t.address.toLowerCase() === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase());
      setSelectedToken({ value: wethToken?.coingecko_id, label: `${wethToken?.name} (${wethToken?.symbol.toUpperCase()})` });
      if (onTokenSelected) {
        onTokenSelected(wethToken);
      }
    },
  }));

  useEffect(() => {
    loadOptions(inputValue);
  }, [inputValue]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue) {
      const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/search?query=${encodeURIComponent(inputValue)}`);
      const data = await response.json();
      setTokens(data);
    } else {
      const response = await fetch(`${ConfigVariables.SERVER_URL}/tokens/fetch-top-tokens`);
      const data = await response.json();
      setTokens(data);
    }
  }

  return (
    <SelectComponent
      placeholder="Search tokens..."
      isClearable
      isSearchable
      onInputChange={setInputValue}
      options={[{
        label: !inputValue ? 'Top Tokens' : null,
        options: tokens?.map((token: any) => ({ value: token.coingecko_id, label: `${token.name} (${token.symbol.toUpperCase()})`, image_thumb: token.image_thumb, symbol: token.symbol }))
      }]}
      value={selectedToken}
      onChange={(value: any) => {
        if (value?.__isNew__) {
          setSelectedToken({ value: value.value, label: value.value });
          if (onTokenSelected) {
            onTokenSelected({ address: value.value }); // Return the entered value as the address
          }
        } else if (value) {
          const token = tokens.find((v) => v.coingecko_id === value.value);
          if (token) {
            setSelectedToken({ value: token?.coingecko_id, label: `${token?.name} (${token?.symbol.toUpperCase()})` });
            if (onTokenSelected) {
              onTokenSelected(token);
            }
          }
        }
      }}
      {...(isUniswap ? { formatCreateLabel: (inputValue) => `Use address ${inputValue}` } : {})} styles={{
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: 'black', // set the background color
          borderRadius: '4px',     // rounded corners
          padding: '10px'
        } as CSSObjectWithLabel)
      }}
      components={{ Option: IconOption }}
    />
  );
})

const IconOption: React.FC<OptionProps<any, false>> = (props) => {
  const { data, label } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={data.image_thumb} alt={data.symbol} width={20} style={{ marginRight: '16px' }} />
        {label}
      </div>
    </components.Option>
  );
};

export default TokenSelect;