import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie-consent';

export const usePageTracking = () => {
    const location = useLocation();  
    const consent = Cookies.get("app-user-cookie-consent");

    useEffect(() => {
      if (consent === 'true') {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
      }
    }, [location]);
  };

interface ITrackEventParams {
  category: string;
  action: string;
  label: string;
  value?: number; // optional, as some events might not have a numerical value
  additionalParams?: { [key: string]: any }; // for any additional parameters
}

export const trackEvent = (eventData: ITrackEventParams) => {
    const consent = Cookies.get("app-user-cookie-consent");

    if (consent === 'true') {
      ReactGA.event({
        category: eventData.category,
        action: eventData.action,
        label: eventData.label,
        value: eventData.value,
        ...eventData.additionalParams,
      });
    }
  };