import { ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from "@chakra-ui/react"
import { ActionButton } from "../../global/buttons/ActionButton"
import { capitalizeWords, formatRateTooltip } from "../../../helpers/watchlistHelpers"
import { DefiRate, WatchlistToken } from "@BoolDigital/sizzle-types"

interface RatesModalProps {
  token: WatchlistToken;
  rates: DefiRate[] | undefined;
  handleClose: () => void;
}

export const RatesModal = ({ token, rates, handleClose }: RatesModalProps) => {
  return (
    <>
      <ModalOverlay
        bg="inherit"
        backdropFilter='auto'
        backdropBlur='2px' />
      <ModalContent bg="black">
        <ModalHeader className="underline underline-offset-2">Rates for {token?.token.symbol.toUpperCase()}</ModalHeader>
        <ModalCloseButton />
        <ModalBody bgColor={'black'}>
          <div className="flex flex-col gap-4">
            {rates?.map((rate: any, index: number) => (
              <div key={index} className="border border-white rounded-2xl p-2 bg-[#234f58]">
                <div className="flex gap-4 justify-between items-center">
                  <div className="flex gap-4 items-center">
                    <img src={rate.protocolLogoUrl} alt={rate.protocol} className="w-10 h-10" />
                    <p className="text-center">{capitalizeWords(rate.protocol)}</p>
                  </div>
                  <div>
                    <strong>{rate.displayRate}%</strong>
                  </div>
                </div>
                <div>
                  {formatRateTooltip(rate)}
                </div>
              </div>
            ))}
          </div>
        </ModalBody>

        <ModalFooter>
          <ActionButton type="button" style="primary" classes="h-[3rem] px-1 py-2" onClick={handleClose}>
            Close
          </ActionButton>
        </ModalFooter>
      </ModalContent>
    </>
  )
}