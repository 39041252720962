import { useState, useEffect } from 'react';
import { ConfigVariables } from '../config';

const useUserAccount = (address: string | undefined, readOnly: boolean, refetch: boolean) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    if(readOnly || !address) {
        console.log("Read Only or no address");
        setIsLoading(false);
        return
    }
    setIsLoading(true);
    fetch(`${ConfigVariables.SERVER_URL}/users/${address}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setIsLoading(false);
        setUser(data);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
        setUser(null)
      });
  }, [address, readOnly, refetch]);

  return { user, isLoading };
}

export default useUserAccount;