import { ConfigVariables } from "../config";

export const createAccount = async ({
    name,
    email, 
    address,
}: {name: string, email: string, address: string}) => {
    try {
        const response = await fetch(`${ConfigVariables.SERVER_URL}/users/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, address }),
        });
    
        if (!response.ok) {
          throw new Error(`There was a problem calling our database: ${response.status}`);
        }
    
        const user = await response.json();
    
        return user;
      } catch (error) {
        console.error('There was a problem creating the user: ', error);
      }
}