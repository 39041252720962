import { priceToClosestTick, NonfungiblePositionManager, Pool, computePoolAddress, FeeAmount, TickMath, Position } from '@uniswap/v3-sdk';
import { Price, Token } from '@uniswap/sdk-core';
import JSBI from 'jsbi'
import { create, all } from 'mathjs';
import poolAbi from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json';
import { SwapRoute, V3Route } from "@uniswap/smart-order-router"
import { BigNumber, ethers } from "ethers";
import bn from 'bignumber.js'
import { UniswapPositionData, UniswapPathStep } from '@BoolDigital/sizzle-types';
import { ContractAddresses } from '../config';

bn.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 })
const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96));

const encodePriceSqrt = (reserve0: BigNumber, reserve1: BigNumber) => {
    if (reserve0.isZero() || reserve1.isZero()) {
        throw new Error("Token reserves must be greater than zero.");
    }

    // Calculate the square root price ratio and scale to Q64.96 format
    return BigNumber.from(
        new bn(reserve1.toString())
            .div(reserve0.toString())
            .sqrt()
            .multipliedBy(new bn(2).pow(96))
            .integerValue(bn.ROUND_DOWN)
            .toFixed()
    );
}

export const getCurrentPrice = async (_provider: ethers.providers.Provider, _token0: string, _token1: string, _token0Decimals: number, _token1Decimals: number, _fee: FeeAmount): Promise<{ price: number, tick: number, tickSpacing: number }> => {
    const oneToken0 = Math.pow(10, _token0Decimals);
    const oneToken1 = Math.pow(10, _token1Decimals);
    const chainId = (await _provider.getNetwork()).chainId;
    const poolAddress = computePoolAddress({
        factoryAddress: ContractAddresses.ethereum.mainnet.UniswapV3Factory,
        tokenA: new Token(chainId, _token0, _token0Decimals),
        tokenB: new Token(chainId, _token1, _token1Decimals),
        fee: _fee
    });

    const poolContract = new ethers.Contract(poolAddress, poolAbi.abi, _provider);
    const [, currentTick]: [BigNumber, number] = await poolContract.slot0();
    const tickSpacing = await poolContract.tickSpacing();

    // Get current price
    const price = (Math.pow(1.0001, currentTick) * oneToken0) / (oneToken1);
    return { price, tick: currentTick, tickSpacing };
}

export const tickToPrice = (tick: number, token0Decimals: number, token1Decimals: number) => {
    let price0 = Math.pow(1.0001, tick) / (10 ** (token1Decimals - token0Decimals));
    return price0
}

export const priceToTick = (price: string, baseDecimals: number, quoteDecimals: number, tickSpacing: number, tokenOrderDifferent: boolean) => {
    if (parseFloat(price) <= 0) return -887272;

    let priceRatio;
    let sqrtPriceX96;
    if (tokenOrderDifferent) {
        // Invert price and adjust to the decimal difference
        const invertedPrice = ethers.utils.parseUnits((1 / parseFloat(price)).toFixed(quoteDecimals), quoteDecimals);
        priceRatio = invertedPrice.mul(BigNumber.from(10).pow(baseDecimals)).div(BigNumber.from(10).pow(quoteDecimals));
        sqrtPriceX96 = encodePriceSqrt(BigNumber.from(10).pow(quoteDecimals), priceRatio);
    } else {
        // Adjust price to the decimal difference
        const directPrice = ethers.utils.parseUnits(price.toString(), baseDecimals);
        priceRatio = directPrice.mul(BigNumber.from(10).pow(quoteDecimals)).div(BigNumber.from(10).pow(baseDecimals));
        sqrtPriceX96 = encodePriceSqrt(BigNumber.from(10).pow(baseDecimals), priceRatio);
    }

    // Calculate the sqrt price    
    let tick = TickMath.getTickAtSqrtRatio(JSBI.BigInt(sqrtPriceX96.toString()));
    return alignTick(tick, tickSpacing);
}

export const alignTick = (tick: number, tickSpacing: number) => {
    const lowerBound = -887272;
    const upperBound = 887272;

    // Align with tick spacing
    const tickRemainder = tick % tickSpacing;
    if (tickRemainder !== 0) {
        if (tickRemainder > tickSpacing / 2) {
            // Round up
            tick += (tickSpacing - tickRemainder);
        } else {
            // Round down
            tick -= tickRemainder;
        }
    }

    // Adjust if out of bounds
    if (tick < lowerBound) {
        // Align with the lower bound
        return lowerBound + (tickSpacing - (lowerBound % tickSpacing));
    } else if (tick > upperBound) {
        // Align with the upper bound
        return upperBound - (upperBound % tickSpacing);
    }

    return tick;
};

export const getMintAmounts = (
        token0: Token, 
        token1: Token, 
        feeTier: number, 
        sqrtPriceX96: JSBI, 
        liquidity: JSBI, 
        currentTick: number,
        minTick: number,
        maxTick: number,
        amount: JSBI,
        from0: boolean = true,
    ) => {
    const pool = new Pool(
        token0,
        token1,
        feeTier,
        sqrtPriceX96,
        liquidity,
        currentTick
    );

    let position: Position;
    if (from0) {
        position = Position.fromAmount0({
            pool: pool,
            tickLower: minTick,
            tickUpper: maxTick,
            amount0: amount,
            useFullPrecision: true,
        })
        return position.mintAmounts.amount1
    } else {
        position = Position.fromAmount1({
            pool: pool,
            tickLower: minTick,
            tickUpper: maxTick,
            amount1: amount,
        })
        return position.mintAmounts.amount0
    }
}

export const getOtherTokenAmount = ({
    currentPrice,
    inputTokenAddress,
    inputTokenDecimals,
    inputTokenAmount,
    outputTokenAddress,
    outputTokenDecimals,
    priceHigh,
    priceLow,
}: {
    currentPrice: number,
    inputTokenAddress: string,
    inputTokenDecimals: number,
    inputTokenAmount: number,
    outputTokenAddress: string,
    outputTokenDecimals: number
    priceLow: number,
    priceHigh: number,
}) => {
    let liquidity: number, tokenAmount: number;

    const inputIsBaseToken = ethers.utils.getAddress(inputTokenAddress) < ethers.utils.getAddress(outputTokenAddress);

    if (inputIsBaseToken) {
        liquidity = (inputTokenAmount) * ((Math.sqrt(currentPrice) * Math.sqrt(priceLow)) / (Math.sqrt(priceLow) - Math.sqrt(currentPrice)));
        tokenAmount = Math.floor(liquidity * (Math.sqrt(currentPrice) - Math.sqrt(priceHigh)));
    } else {
        liquidity = (inputTokenAmount) * ((Math.sqrt(currentPrice) * Math.sqrt(priceHigh)) / (Math.sqrt(priceHigh) - Math.sqrt(currentPrice)));
        tokenAmount = Math.floor(liquidity * (Math.sqrt(priceLow) - Math.sqrt(currentPrice)));
    }

    return tokenAmount;
}

export function getToken0Amounts(token1Amount: number, currentTick: number, tickLow: number, tickHigh: number, decimal0: number, decimal1: number) {
    let Pa = tickToPrice(tickLow, decimal0, decimal1);
    let Pb = tickToPrice(tickHigh, decimal0, decimal1);
    let Price = tickToPrice(currentTick, decimal0, decimal1);

    const tokenAmount0 = (token1Amount * (Math.sqrt(Pb) - Math.sqrt(Price))) / ((Math.sqrt(Price) - Math.sqrt(Pa)) * Math.sqrt(Price) * Math.sqrt(Pb))

    return tokenAmount0;
}

export function getToken1Amounts(token0Amount: number, currentTick: number, tickLow: number, tickHigh: number, decimal0: number, decimal1: number) {
    let Pa = tickToPrice(tickLow, decimal0, decimal1);
    let Pb = tickToPrice(tickHigh, decimal0, decimal1);
    let Price = tickToPrice(currentTick, decimal0, decimal1);

    const tokenAmount1 = (token0Amount * (Math.sqrt(Price) - Math.sqrt(Pa)) * Math.sqrt(Price) * Math.sqrt(Pb)) / (Math.sqrt(Pb) - Math.sqrt(Price))

    return tokenAmount1;
}

export const formatUniswapPositionData = (data: any): UniswapPositionData[] => {
    if (!data.data || !data.data.positions) return [];

    return data.data.positions.map((p: any) => {
        const position: UniswapPositionData = {
            id: p.id,
            owner: p.owner,
            nonce: p.nonce,
            liquidity: p.liquidity,
            depositedToken0: p.depositedToken0,
            depositedToken1: p.depositedToken1,
            feeGrowthGlobal0X128: p.pool.feeGrowthGlobal0X128,
            feeGrowthGlobal1X128: p.pool.feeGrowthGlobal1X128,
            feeGrowth0Low: p.tickLower.feeGrowthOutside0X128,
            feeGrowth0Hi: p.tickUpper.feeGrowthOutside0X128,
            feeGrowthInside0LastX128: p.feeGrowthInside0LastX128,
            feeGrowth1Low: p.tickLower.feeGrowthOutside1X128,
            feeGrowth1Hi: p.tickUpper.feeGrowthOutside1X128,
            feeGrowthInside1LastX128: p.feeGrowthInside1LastX128,
            tickLower: p.tickLower.tickIdx,
            tickUpper: p.tickUpper.tickIdx,
            token0Address: p.pool.token0.id,
            token0Symbol: p.pool.token0.symbol,
            token0Decimals: p.pool.token0.decimals,
            token1Address: p.pool.token1.id,
            token1Symbol: p.pool.token1.symbol,
            token1Decimals: p.pool.token1.decimals,
            currentTick: p.pool.tick,
            currentSqrtPrice: p.pool.sqrtPrice,
            currentPoolLiquidity: p.pool.liquidity,
            feeTier: p.pool.feeTier
        }
        // calculate amounts and fees and add to object
        const { amount0, amount1 } = calculatePositionAmounts(position);
        const { uncollectedFees0, uncollectedFees1 } = calculatePositionUnclaimedFees(
            position.feeGrowthGlobal0X128,
            position.feeGrowthGlobal1X128,
            position.feeGrowth0Low,
            position.feeGrowth0Hi,
            position.feeGrowthInside0LastX128,
            position.feeGrowth1Low,
            position.feeGrowth1Hi,
            position.feeGrowthInside1LastX128,
            position.liquidity,
            position.token0Decimals,
            position.token1Decimals,
            position.tickLower,
            position.tickUpper,
            position.currentTick
        );

        position.amount0 = amount0;
        position.amount1 = amount1;
        position.uncollectedFees0 = uncollectedFees0;
        position.uncollectedFees1 = uncollectedFees1;

        return position;
    });
}

export const calculatePositionAmounts = (position: UniswapPositionData) => {
    const token0 = new Token(1, position.token0Address, Number(position.token0Decimals), position.token0Symbol)
    const token1 = new Token(1, position.token1Address, Number(position.token1Decimals), position.token1Symbol)

    const sqrtPriceX96 = JSBI.BigInt(position.currentSqrtPrice)
    const liquidity = JSBI.BigInt(position.liquidity)

    const pool = new Pool(token0, token1, Number(position.feeTier), sqrtPriceX96, liquidity, Number(position.currentTick))

    const tickLower = Number(position.tickLower)
    const tickUpper = Number(position.tickUpper)

    const positionSDK = new Position({
        pool,
        tickLower,
        tickUpper,
        liquidity: JSBI.BigInt(position.liquidity),
    })

    const amount0 = positionSDK.amount0.toFixed()
    const amount1 = positionSDK.amount1.toFixed()
    return { amount0, amount1 }
}

export const calculatePositionUnclaimedFees = (
    feeGrowthGlobal0: string,
    feeGrowthGlobal1: string,
    feeGrowth0Low: string,
    feeGrowth0Hi: string,
    feeGrowthInside0Last: string,
    feeGrowth1Low: string,
    feeGrowth1Hi: string,
    feeGrowthInside1Last: string,
    liquidity: string,
    decimals0: number,
    decimals1: number,
    tickLower: number,
    tickUpper: number,
    tickCurrent: number
) => {
    const Q128 = BigNumber.from(2).pow(128);

    const feeGrowthGlobal_0 = BigNumber.from(feeGrowthGlobal0);
    const feeGrowthGlobal_1 = BigNumber.from(feeGrowthGlobal1);

    const tickLowerFeeGrowthOutside_0 = BigNumber.from(feeGrowth0Low);
    const tickLowerFeeGrowthOutside_1 = BigNumber.from(feeGrowth1Low);

    const tickUpperFeeGrowthOutside_0 = BigNumber.from(feeGrowth0Hi);
    const tickUpperFeeGrowthOutside_1 = BigNumber.from(feeGrowth1Hi);

    const tickLowerFeeGrowthBelow_0 = tickCurrent >= tickLower ? tickLowerFeeGrowthOutside_0 : feeGrowthGlobal_0.sub(tickLowerFeeGrowthOutside_0);
    const tickLowerFeeGrowthBelow_1 = tickCurrent >= tickLower ? tickLowerFeeGrowthOutside_1 : feeGrowthGlobal_1.sub(tickLowerFeeGrowthOutside_1);

    const tickUpperFeeGrowthAbove_0 = tickCurrent >= tickUpper ? feeGrowthGlobal_0.sub(tickUpperFeeGrowthOutside_0) : tickUpperFeeGrowthOutside_0;
    const tickUpperFeeGrowthAbove_1 = tickCurrent >= tickUpper ? feeGrowthGlobal_1.sub(tickUpperFeeGrowthOutside_1) : tickUpperFeeGrowthOutside_1;

    const fr_t1_0 = feeGrowthGlobal_0.sub(tickLowerFeeGrowthBelow_0).sub(tickUpperFeeGrowthAbove_0);
    const fr_t1_1 = feeGrowthGlobal_1.sub(tickLowerFeeGrowthBelow_1).sub(tickUpperFeeGrowthAbove_1);

    const uncollectedFees_0 = BigNumber.from(liquidity).mul(BigNumber.from(fr_t1_0).sub(BigNumber.from(feeGrowthInside0Last))).div(Q128);
    const uncollectedFees_1 = BigNumber.from(liquidity).mul(BigNumber.from(fr_t1_1).sub(BigNumber.from(feeGrowthInside1Last))).div(Q128);

    const uncollectedFeesAdjusted_0 = ethers.utils.formatUnits(uncollectedFees_0, decimals0);
    const uncollectedFeesAdjusted_1 = ethers.utils.formatUnits(uncollectedFees_1, decimals1);

    return {
        uncollectedFees0: uncollectedFeesAdjusted_0,
        uncollectedFees1: uncollectedFeesAdjusted_1,
    };
}

export const handlePosition = (position: UniswapPositionData) => {
    const url0: string = position.token0Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(position.token0Address)}/logo.png?raw=true`
    const url1: string = position.token1Img ?? `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(position.token1Address)}/logo.png?raw=true`
    const depositedUSD: number = (parseFloat(position.amount0 ?? '0') * (position.token0PriceUSD ?? 0)) + (parseFloat(position.amount1 ?? '0') * (position.token1PriceUSD ?? 0))
    const uncollectedFeesUSD = (parseFloat(position.uncollectedFees0 ?? '0') * (position.token0PriceUSD ?? 0)) + (parseFloat(position.uncollectedFees1 ?? '0') * (position.token1PriceUSD ?? 0))
    let minPrice: number;
    let maxPrice: number | null;
    if (position.tickLower <= -887270) {
        minPrice = 0;
    } else {
        minPrice = Math.pow(1.0001, position.tickLower);
    }

    if (position.tickUpper >= 887270) {
        maxPrice = null;
    } else {
        maxPrice = Math.pow(1.0001, position.tickUpper);
    }

    return {
        ...position,
        url0,
        url1,
        depositedUSD,
        uncollectedFeesUSD,
        minPrice,
        maxPrice,
    }
}

export const findPosition = (positions: any[], p: any) => {
    for (const obj1 of positions) {
        if (
            obj1.feeTier === p.feeTier &&
            obj1.token0Address === p.token0.id &&
            obj1.token1Address === p.token1.id
        ) {
            return obj1.id; // Found a matching object
        }
    }
    return null;
}