import Icon from "../../assets/images/sizzle-logo-animated.svg"

interface SizzleAnimatedProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const SizzleAnimated = ({ size }: SizzleAnimatedProps) => {
  const sizes = {
    'xs': '15px',
    'sm': '30px',
    'md': '60px',
    'lg': '125px',
  }

  return (
    <div className="sizzle-animated">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height={sizes[size || "md"]} width={sizes[size || "md"]} className="mx-auto text-center flex items-center" viewBox="186.6 0 124.24 160.17">
        <defs>
        </defs>

        <g>
          <path className="cls-2" d="m241.68,32.87s-21.96,26.21,9.56,57.38c31.52,31.17,14.88,59.15-15.58,72.25,0,0,33.64-33.65-1.42-61.27-35.06-27.63-5.66-65.88,7.44-68.36Z" />
          <path className="cls-2" d="m208.02,69s-15.84,18.91,6.9,41.39c22.74,22.48,10.73,42.66-11.24,52.12,0,0,24.26-24.27-1.03-44.2-25.29-19.93-4.08-47.52,5.37-49.31Z" />
          <path className="cls-2" d="m281.14,0s-27.53,32.86,11.99,71.93c39.52,39.07,18.65,74.15-19.54,90.57,0,0,42.17-42.18-1.78-76.81-43.95-34.63-7.1-82.58,9.33-85.69Z" />
        </g>
      </svg>
    </div>
  )
}