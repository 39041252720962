interface SwitchProps {
  checked?: boolean;
  changeEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  isDisabled?: boolean;
}

export const Switch = ({ checked, changeEvent, id, isDisabled }: SwitchProps) => {
  return (
    <label id={id} className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" value="" className="sr-only peer" checked={checked} onChange={changeEvent} />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#73B0BE]"></div>
    </label>
  )
}