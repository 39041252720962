import { useEffect, useState } from "react";
import { BitqueryToken } from "@BoolDigital/sizzle-types";
import { ethers } from "ethers";
import { ConfigVariables } from "../config";

const useCurrentHoldings = (address: string | undefined) => {
    const [holdings, setHoldings] = useState<BitqueryToken[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        if (!address) return

        const getHoldings = async () => {
            setIsLoading(true);
            const queryParams = new URLSearchParams({
                address: ethers.utils.getAddress(address) || '',
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/current?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setHoldings(data.holdings);
            setIsLoading(false);
        }

        getHoldings();
    }, [address])

    return { holdings, isLoading };
}

export default useCurrentHoldings;