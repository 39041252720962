import { ReserveDataHumanized } from '@aave/contract-helpers';
import { FormatReserveUSDResponse, FormatUserSummaryResponse } from '@aave/math-utils';
import { BigNumber, ethers } from 'ethers';

export const getTokenValue = (
    amount: BigNumber | undefined, 
    tokenDecimals: number | undefined, 
    price: BigNumber | undefined, 
    priceDecimals: number | undefined) => {
        if(!amount || !tokenDecimals || !price || !priceDecimals) {
            return;
        }
        const solidityMath = (amount.mul(price)).div(BigNumber.from(1*10**priceDecimals))
        return ethers.utils.formatUnits(solidityMath, tokenDecimals);
}

export const formatTokenValue = (value: string | undefined) => {
    if (!value) {
        return;
    }
    return parseFloat(value).toFixed(2);
}

export const processSupplies = (summary?: FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse>) => {
    if (!summary) return;
    return summary.userReservesData.filter((r) => r.scaledATokenBalance && parseFloat(r.scaledATokenBalance) > 0);
}

export const processBorrows = (summary?: FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse>) => {
    if (!summary) return;
    return summary.userReservesData.filter((r) => (r.stableBorrowsUSD && parseFloat(r.stableBorrowsUSD) > 0) || (r.variableBorrowsUSD && parseFloat(r.variableBorrowsUSD) > 0));
}