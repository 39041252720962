import { ethers } from 'ethers';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

interface WalletContextType {
  walletAddress: string | undefined;
  setWalletAddress: (address?: string) => void;
  readOnly: boolean;
  setReadOnly: (readOnly: boolean) => void;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWalletContext must be used within a WalletContextProvider');
  }
  return context;
};

interface WalletContextProviderProps {
    children: ReactNode;
  }
export const WalletContextProvider: React.FC<WalletContextProviderProps> = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState<string | undefined>();
  const [readOnly, setReadOnly] = useState(false);
  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (isConnected && address) {
      setWalletAddress(ethers.utils.getAddress(address?.toString()));
      setReadOnly(false);
    }
  }, [isConnected, address]);

  const value: WalletContextType = {
    walletAddress,
    setWalletAddress,
    readOnly,
    setReadOnly,
  };

  return <WalletContext.Provider value={value}>{children}</WalletContext.Provider>;
};