import { useEffect, useState } from 'react';
import { TotalPortfolioValue, BitqueryToken, ChartRange } from '@BoolDigital/sizzle-types';
import { ethers } from 'ethers';
import { ConfigVariables } from '../config';

const usePortfolioHistory = (address: string | undefined, range: ChartRange) => {
    const [total, setTotal] = useState<Record<string, TotalPortfolioValue>>();
    const [holdings, setHoldings] = useState<BitqueryToken[]>();
    const [chartLoading, setChartLoading] = useState<boolean>(false);
    useEffect(() => {
        const getData = async () => {
            setChartLoading(true);
            if (!address) {
                setChartLoading(false)
                return
            }
            const queryParams = new URLSearchParams({
                address: ethers.utils.getAddress(address || ''),
                range: range,
            })

            try {
                let endpoint = `${ConfigVariables.SERVER_URL}/portfolio/historical`
                const response = await fetch(`${endpoint}?${queryParams}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                setTotal(data.total);
                setHoldings(data.holdings);
                return;
            } catch (err) {
                console.log('ERROR GETTING PORTFOLIO HISTORY: ', err);
            } finally {
                setChartLoading(false);
            }
        }

        getData();
    }, [address, range]);
    return { total, holdings, chartLoading };
};

export default usePortfolioHistory;