import { Box, Button, Divider, Flex, Text, Icon, Input, Modal, ModalBody, ModalContent, ModalOverlay, Tooltip } from "@chakra-ui/react";
import ConnectWalletButton from "./ConnectWalletButton";
import { RiQuestionLine } from "react-icons/ri";
import { useWalletContext } from "../hooks/useWalletContext";
import { useState } from "react";
import { ethers } from "ethers";
import { trackEvent } from "../helpers/googleAnalyticsHelpers";

const ConnectWalletModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {
    const { setWalletAddress, setReadOnly, walletAddress, readOnly } = useWalletContext();
    const [addressInput, setAddressInput] = useState<{ value: string, error?: string }>({ value: "" });

    const handleAddressInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressInput({ value: event.target.value });
    }

    const handleViewWallet = () => {
        try {
            ethers.utils.getAddress(addressInput?.value ?? '');
            // valid address, set page to read-only and set wallet address to value
            // TO-DO
            setWalletAddress(addressInput?.value ?? '');
            setReadOnly(true);
            trackEvent({
                category: 'connect_wallet',
                action: 'connect_read_only_wallet',
                label: 'Connect Read Only Button',
                additionalParams: {
                    'wallet_address': addressInput?.value,
                    'connection_type': 'read-only',
                    'success': true
                }
            });
            onClose();
        } catch (e) {
            // invalid address, set error
            setAddressInput({ value: addressInput?.value ?? '', error: 'Enter a valid address' });
            trackEvent({
                category: 'connect_wallet',
                action: 'connect_read_only_wallet',
                label: 'Connect Read Only Button',
                additionalParams: {
                    'wallet_address': addressInput?.value,
                    'connection_type': 'read-only',
                    'success': false
                }
            });
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody padding={8}>
                    <Flex flexDir={'column'} alignItems={'center'} gap={4}>
                        <div className="text-sm mb-4">
                            By connecting a wallet, you agree to our
                            {' '}<a href="https://sizzle.fi/terms-of-service/" target="_blank" className="text-blue-500">Terms of Service</a>{' '}
                            and consent to our
                            {' '}<a href="https://sizzle.fi/privacy-policy/" target="_blank" className="text-blue-500 mx-1">Privacy Policy</a>.{' '}
                            <br /><span className="text-gray-400">(Last updated 2024-02-02)</span>
                        </div>
                        <ConnectWalletButton
                            closeFn={onClose}
                            bgColor="mint.400"
                            _hover={{
                                bgColor: 'mint.300',
                            }}>Connect Wallet</ConnectWalletButton>
                        <Divider />
                        <Flex
                            alignItems={'center'}
                            gap={2}
                            css={{
                                '.custom-tooltip__label': {
                                    padding: '20px', // Set your desired padding value
                                },
                            }}>
                            <Text textAlign={'center'}><strong>Read-only mode:</strong></Text>
                            <Tooltip
                                label="Get a comprehensive view of any wallet, including those not owned by you. In read-only mode, you can browse through the connected wallet's details without the ability to perform any actions. Feel free to enter any valid Ethereum address to explore."
                                aria-label="Total Balance of Deposited Liquidity"
                                hasArrow
                            >
                                <Flex alignItems={'center'}>
                                    <Icon as={RiQuestionLine} boxSize={4} />
                                </Flex>
                            </Tooltip>
                        </Flex>
                        <Box>
                            <Input placeholder="Wallet Address" onChange={handleAddressInput} value={addressInput?.value} />
                            {addressInput?.error && <Text pl={1} color={'red'} fontSize={'xs'} fontWeight={'bold'}>{addressInput?.error}</Text>}
                        </Box>
                        <Button
                            variant={'outline'}
                            borderColor={'mint.300'}
                            borderWidth={2}
                            color={'mint.300'}
                            onClick={handleViewWallet}>View Wallet</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ConnectWalletModal;