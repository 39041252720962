import { ChartRange } from '@BoolDigital/sizzle-types';
import { useState, useEffect } from 'react';
import { ConfigVariables } from '../../config';

const useTokenPriceHistory = (address: string | undefined, range: ChartRange) => {
    const [tokenData, setTokenData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams({
                    range: range,
                })

            try {
                let endpoint = `${ConfigVariables.SERVER_URL}/portfolio/historical/${address ?? '0x'}`
                const response = await fetch(`${endpoint}?${queryParams}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                setTokenData(data);
                return;
            } catch (err) {
                console.log('errorrrrrrrr', err);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [address, range]);

    return { tokenData, loading };
};

export default useTokenPriceHistory;