import { useState, useEffect } from 'react';
import { ConfigVariables } from '../config';
import { DefiRate, WatchlistToken } from '@BoolDigital/sizzle-types';

export const useWatchedTokenDefiRates = (token: WatchlistToken): { rates?: DefiRate[], isLoading: boolean, isError: boolean } => {
  const [data, setData] = useState<DefiRate[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchWatchedTokenDefiRates = async (token: WatchlistToken) => {
        if(!token) {
            return;
        }
        setIsLoading(true);

        try {
            const queryParams = new URLSearchParams({
                userTokenWatchlistId: token.userTokenWatchlistId.toString(),
                tokenAddress: token.token.address,
                currentPrice: token.currentPrice.toString()
            })
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/watchlist/token-defi-rates?${queryParams}`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
          
            if (!response.ok) {
                throw new Error(`Error fetching defi rates: ${response.statusText}`);
            }
            
            setData(await response.json() as DefiRate[]);
        } catch (e) {
            setIsError(true);
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    fetchWatchedTokenDefiRates(token)
  }, [token]);

  return { rates: data, isLoading, isError };
}