import React, { ReactNode, MouseEvent } from 'react';
interface ActionButtonProps {
  children: ReactNode;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  style: 'primary' | 'primary-disabled' | 'primary-xs' | 'secondary' | 'ghost' | 'ghost-xs' | 'ghost-border' | 'danger'; // Add more as needed
  classes?: string;
  isDisabled?: boolean;
  message?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const ActionButton = ({ children, id, style, classes, isDisabled, message, onClick }: ActionButtonProps) => {
  const classList: Record<string, string> = {
    'primary': 'bg-[#73B0BE] hover:bg-[#6E7777] rounded-md',
    'primary-disabled': 'bg-gray-700 cursor-not-allowed rounded-md text-white',
    'primary-xs': 'bg-[#73B0BE] hover:bg-[#6E7777] rounded-md',
    'secondary': 'bg-[#CFCFCF] hover:bg-[#6E7777] hover:text-white text-black rounded-md px-4',
    'ghost': 'bg-none',
    'ghost-border': 'bg-none border border-[#73B0BE] hover:bg-[#6E7777] hover:text-white rounded-md',
  };

  return (
    <button id={id} data-tip={message} disabled={isDisabled} className={`${classList[style]} ${classes ?? ''}`} onClick={onClick}>
      {children}
    </button>
  );
};
