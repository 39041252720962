import { useState, useEffect } from 'react';
import { ConfigVariables } from '../config';
import { WatchlistToken } from '@BoolDigital/sizzle-types';

const fetchUserWatchlist = async (userAddress: string) => {
  const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/watchlist/${userAddress}`);
  
  if (!response.ok) {
    throw new Error(`Error fetching watchlist: ${response.statusText}`);
  }

  return response.json();
}

export const useUserWatchlist = (userAddress?: string, modalToggled?: boolean, userLoading?: boolean): { watchlist: WatchlistToken[] | null, isLoading: boolean, isError: boolean } => {
  const [data, setData] = useState<WatchlistToken[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!userLoading) {
      fetchUserWatchlist(userAddress ?? 'default')
      .then(responseData => {
        setData(responseData as WatchlistToken[]);
        setIsLoading(false);
      })
      .catch(error => {
        setIsError(true);
        setIsLoading(false);
        console.error(error);
      });
    }
  }, [userAddress, modalToggled, userLoading]);

  return { watchlist: data, isLoading, isError };
}